.listiteminbtn{
    margin-top: 5px;
    background-color: #ffffff;
    color:#575858;
    border: 2px solid #c4c4c4; /* Green */
    transition-duration: 0.4s;
    width:100%;
    text-align: center;
    padding: 8px;
    letter-spacing: 0.5px;
    font-size:15px;
    border-radius:8px;
    font-weight: 500;
   
}
.listiteminbtnIntrest{
  margin-top: 5px;
  background-color: #ffffff;
  color:#575858;
  border: 2px solid #c4c4c4; /* Green */
  transition-duration: 0.4s;
  width:100%;
  text-align: center;
  padding: 8px;
  letter-spacing: 0.5px;
  font-size:15px;
  border-radius:8px;
  font-weight: 500;
 
}
.listiteminbtnIntrest:hover{
  margin-top: 5px;
  background-color: #ffffff;
  color:#0070ba;
  border: 2px solid #c4c4c4; /* Green */
  transition-duration: 0.4s;
  width:100%;
  text-align: center;
  padding: 8px;
  letter-spacing: 0.5px;
  font-size:15px;
  border-radius:8px;
  font-weight: 500;
 
}
.listiteminbtnShort
{
    margin-top: 5px;
    background-color: #ffffff;
    color:#575858;
    border: 2px solid #c4c4c4; /* Green */
    transition-duration: 0.4s;
    width:100%;
    text-align: center;
    padding: 8px;
    letter-spacing: 0.5px;
    font-size:15px;
    border-radius:8px;
    font-weight: 500;
   
}
.listiteminbtnShort:hover
{
    margin-top: 5px;
    background-color: #ffffff;
    color:#05aab0;
    border: 2px solid #c4c4c4; /* Green */
    transition-duration: 0.4s;
    width:100%;
    text-align: center;
    padding: 8px;
    letter-spacing: 0.5px;
    font-size:15px;
    border-radius:8px;
    font-weight: 500;
   
}
.listiteminbtnactiveShort{
  margin-top: 5px;
  background-color: #ffffff;
  color:#05aab0;
  border: 2px solid #c4c4c4; /* Green */
  transition-duration: 0.4s;
  width:100%;
  text-align: center;
  padding: 8px;
  letter-spacing: 0.5px;
  font-size:15px;
  border-radius:8px;
  font-weight: 500;

}
.listiteminbtnactive{
  margin-top: 5px;
  background-color: rgb(218, 95, 95);
  color:#fff;
  border: 2px solid #fff; /* Green */
  transition-duration: 0.4s;
  width:100%;
  text-align: center;
  padding: 8px;
  letter-spacing: 0.5px;
  font-size:15px;
  border-radius:8px;
  font-weight: 500;

}
.listiteminrejectbtn{
  margin-top: 5px;
  background-color: #df1e10da;
  color:#fff;
  border: 2px solid #fff; /* Green */
  transition-duration: 0.4s;
  width:100%;
  text-align: center;
  padding: 8px;
  font-weight: 500;
  font-size:15px;
  border-radius:6px;
  letter-spacing: 0.5px;
 
}
.listiteminrequestbtn{
  margin-top: 5px;
  background-color: #0070ba;
  color:#fff;
  border: 2px solid #fff; /* Green */
  transition-duration: 0.4s;
  width:100%;
  text-align: center;
  padding: 8px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size:15px;
  border-radius:8px;

 
}
.listitemacceptbtn{
  margin-top: 5px;
  background-color: #00ba7c;
  color:#fff;
  border: 2px solid #00ba7c; /* Green */
  transition-duration: 0.4s;
  width:100%;
  text-align: center;
  padding: 8px;
  font-weight: 500;
  font-size:15px;
  border-radius:6px;
  font-weight: 500;
  letter-spacing: 0.5px;
 
}

 
  .listpopuprjbtn{
    width:100%;
    background-color: #fff;
    text-align: center;
    border: 2px solid #df1e10da;
    color:#df1e10da;
    border-radius:6px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 8px;
  }
  .listpopuprjbtn:hover{
    width:100%;
    background-color: #df1e10da;
    text-align: center;
    border: 2px solid #df1e10da;
    color:#fff;
    border-radius:6px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 8px;
  }
  .listpopupAcptbtn{
    width:100%;
    background-color: #fff;
    text-align: center;
    border: 2px solid #00ba7c;
    color:#00ba7c;
    border-radius:6px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 8px; 
  }
  .listpopupAcptbtn:hover{
    width:100%;
    background-color: #00ba7c;
    text-align: center;
    border: 2px solid #00ba7c;
    color:#fff;
    border-radius:6px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 8px; 
  }
  .listpopupcamcelbtn{
    width:100%;
    background-color: #fff;
    text-align: center;
    border: 2px solid #0070ba;
    color:#0070ba;
    border-radius:8px;
    font-weight: 500;
    padding: 8px; 
    letter-spacing: 0.5px;
  }
  .listpopupcamcelbtn:hover{
    width:100%;
    background-color: #0070ba;
    text-align: center;
    border: 2px solid #0070ba;
    color:#fff;
    border-radius:6px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 8px; 
  }
  .deactivationHead
  {

    font-family: 'Ubuntu-Medium';

  }

.deactivationSub
  {

    font-family: 'Ubuntu-Regular';
    font-size: 15px;
  }

.DeactivationCard
{
  background-color: #fff;
  border-color: #fe002a;
  margin-top: 20px;
}
.listiteminbtnShort:lang(ml){
  font-size: 13px !important;
}
.listiteminbtnactiveShort:lang(ml){
  font-size: 13px !important;
}
.listpopupcamcelbtn:lang(ml){
  font-size: 13px !important;
}
.listiteminbtnIntrest:lang(ml){
  font-size: 13px !important;
}
.listiteminrequestbtn:lang(ml){
  font-size: 13px !important;
}
.listitemacceptbtn:lang(ml){
  font-size: 13px !important;
}
.listiteminrejectbtn:lang(ml){
  font-size: 13px !important;
}
.deactivationSub:lang(ml){
  font-size: 14px !important;
}