body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","Nunito","Nunito-SemiBold"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Ubuntu-Light';
  src: local('Ubuntu-Light'), url(./Font/Ubuntu-Light.ttf) format('truetype');
  unicode-range: U+00-FF, U+980-9FF;
 
}
@font-face {
  font-family: 'Ubuntu-Bold';
  src: local('Ubuntu-Bold'), url(./Font/Ubuntu-Bold.ttf) format('truetype');
  unicode-range: U+00-FF, U+980-9FF;
 
}
@font-face {
  font-family: 'Ubuntu-Regular';
  src: local('Ubuntu-Regular'), url(./Font/Ubuntu-Regular.ttf) format('truetype');
  unicode-range: U+00-FF, U+980-9FF;
 
}
@font-face {
  font-family: 'Ubuntu-Medium';
  src: local('Ubuntu-Medium'), url(./Font/Ubuntu-Medium.ttf) format('truetype');
  unicode-range: U+00-FF, U+980-9FF;
 
}
@media screen and (min-width: 1200px){
 
  .DetailButtons {
    flex: 0 0 20%;
    max-width: 20%;
    padding-right: 10px;
  }
}

@media screen and (min-width: 992px){
 
  .DetailButtons {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 768px){
 
  .DetailButtons {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 576px){
 
  .DetailButtons {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
}
