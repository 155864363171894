@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:800);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:800);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","Nunito","Nunito-SemiBold"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Ubuntu-Light';
  src: local('Ubuntu-Light'), url(/static/media/Ubuntu-Light.277289c5.ttf) format('truetype');
  unicode-range: U+00-FF, U+980-9FF;
 
}
@font-face {
  font-family: 'Ubuntu-Bold';
  src: local('Ubuntu-Bold'), url(/static/media/Ubuntu-Bold.e00e2a77.ttf) format('truetype');
  unicode-range: U+00-FF, U+980-9FF;
 
}
@font-face {
  font-family: 'Ubuntu-Regular';
  src: local('Ubuntu-Regular'), url(/static/media/Ubuntu-Regular.2505bfbd.ttf) format('truetype');
  unicode-range: U+00-FF, U+980-9FF;
 
}
@font-face {
  font-family: 'Ubuntu-Medium';
  src: local('Ubuntu-Medium'), url(/static/media/Ubuntu-Medium.8e22c2a6.ttf) format('truetype');
  unicode-range: U+00-FF, U+980-9FF;
 
}
@media screen and (min-width: 1200px){
 
  .DetailButtons {
    flex: 0 0 20%;
    max-width: 20%;
    padding-right: 10px;
  }
}

@media screen and (min-width: 992px){
 
  .DetailButtons {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 768px){
 
  .DetailButtons {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 576px){
 
  .DetailButtons {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  
}
.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  overflow: hidden;
}

.button{
  background-color:transparent;
  border: white 2px solid;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px 1px;
  cursor: pointer;
  font-family: 'Ubuntu-Regular';
}
.colorcontainer{
  background-image: -webkit-radial-gradient(center top, circle farthest-side, #2bacb1, #0b4f52 130%);
  background-image: radial-gradient(circle farthest-side at center top, #2bacb1, #0b4f52 130%)
}
.iconcontainer{
margin-top: 50px;
}
.itemscentr{
  align-items: center;
  text-align:center;
}
.logincontainer{
  min-height:93vh;
}

.usercontainer{
  min-height:90vh;
}
.fontsizelimit{
  font-size: 10px;
}
.imageupload{
  background: #b4b4b4;
  border-radius: 10px;
}
.aligncenter{
  align-items: center;
  text-align:center;
  margin-top: 80px;
  width: 100%;
}
.aligncenter1{
  align-items: center;
  text-align:center;
  margin-top: 40px;
  width: 100%;
}
.aligitemncenter{
  align-items: center;
  text-align:center;
  width: 100%;
  height:100%;
}
.height{
  height:325px;
  align-items: center;
  text-align:center;
  padding: 25px;
}
.height1{
  height:200px;
  align-items: center;
  text-align:center;
  padding: 25px;
}
.boxabout{
  border:1.4px solid #cfcfcf;
  border-radius: 10px;
  margin: 10px;
}
.fixedbox{
  height:90vh;
}
.fixedbox1{
  height:90vh;
  overflow:scroll;
}
.fixedbox2{
  height:93vh;
  overflow:scroll;
}
.change{
  height:80vh;
}
@media only screen and (max-width: 600px) {
  .navbottom{margin-bottom: 10px;margin-top: 10px;}
  .recomend-image{
    width:100%;
    height: auto;
    object-fit: contain;
    border: 1px solid #ddd;
    padding: 3px;
  }
  .scrollBox{
    overflow:scroll;
  }
  
  .scrollBoxadmin{
    overflow: hidden;
  }
  .padii{
    margin-bottom: 10px;
  }
  .pads {
    margin-left:0px;
    margin-right:0px; 
  }
  .mobpad{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .cover-image{
    display: none;
  }
  .probaground{
    width:100%;
    height:300px;
    border-radius:10px;
    background-repeat: contain;
      object-fit:none; 
  }
  .example1 {
    height:45vh;
    width: 100%;
    background-repeat: no-repeat;
    object-fit:none; 
  }
  .hidesection{
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .navbottom{
    margin-bottom: 10px;
    margin-top: 10px;
    display: inline-block;
    line-height: 20px;
  }
  .recomend-image{
    width: 100%;
    object-fit: contain;
    border: 1px solid #ddd;
    padding: 3px;
  }
  .scrollBox{
    overflow: scroll;
  }
  .padii{
    margin-bottom: 10px;
  }
  .pads {
    margin-left:30px;
    margin-right:30px; 
  }
  .example1 {
    height:50vh;
    width: 100%;
    background-repeat: no-repeat;
    object-fit:none; 
  }
  .mobpad{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .cover-image{
    width:100%;
    height:80vh;
    object-fit: contain;
    border-radius: 10px;
  }
  .probaground{
    width:100%;
    height:300px;
    border-radius:10px;
    background-repeat: contain;
      object-fit:none; 
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .navbottom{margin-bottom:0px;
    margin-top: 0px;
  display: inline-block;
  line-height: 20px;
}
  .scrollBox{
    height:100vh;
    overflow: scroll;
  }
  .padii{
    margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
    margin-bottom: 10px;
  }
  .pads {
    margin-left:30px;
    margin-right:30px; 
  }
  .padss {
    margin-left:10px;
    margin-right:10px; 
  }
  .listpad{
    margin:40px;
    margin-top: 0px;
  }
  .example1 {
    height:100%;
    width: 100%;
    background-repeat: no-repeat;
    object-fit:fill; 
  }
  .mobpad{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .cover-image{
    width:100%;
    height:80vh;
    object-fit: contain;
    border-radius: 10px;
  }
  .paddesk{
    margin:10px;
  }
  .probaground{
    width:100%;
    height:300px;
    border-radius:10px;
    background-repeat: no-repeat;
      object-fit:fill; 
  }
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .navbottom{margin-bottom:0px;
    margin-top: 0px;
  display: inline-block;
  line-height: 20px;
}
  .scrollBox{
    height:100vh;
    overflow: scroll;
  }
  .padii{
    margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
    margin-bottom: 10px;
  }
  .pads {
    margin-left:30px;
    margin-right:30px; 
  }
  .padss {
    margin-left:10px;
    margin-right:10px;
  }
  .listpad{
    margin-top: 0px;
    margin:40px;
  }
  .showhide{
    display: none;
  }
  .example1 {
    height:100%;
    width: 100%;
    background-repeat: no-repeat;
    object-fit:fill; 
  }
  .mobpad{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .cover-image{
    width:100%;
    height:80vh;
    object-fit: contain;
    border-radius: 10px;
  }
  .probaground{
    width:100%;
    height:300px;
    border-radius:10px;
    background-repeat: no-repeat;
      object-fit:fill; 
  }
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .navbottom{margin-bottom:0px;
    margin-top: 0px;
  display: inline-block;
  line-height: 20px;
}
  .recomend-image{
    width: 100%;
    height: auto;
    border: 1px solid #ddd;

  padding: 3px;
 
}
.ListItemSub{
font-size:14px ;
font-family: "Nunito-SemiBold";
color: #000000;
font-weight: 600;
 
}
.scrollBox{
  height:85vh;
  overflow: scroll;
}
.padii{
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.pads {
  margin-left:30px;
  margin-right:30px; 
}
.padss {
  margin-left:10px;
  margin-right:10px;
}
.listpad{
  margin:40px;
  margin-top: 0px;
}
.ppad{
  margin-left:50px;
  margin-right:50px; 
}
.showhide{
  display: none;
}
.example1 {
  height:100%;
  width: 100%;
  background-repeat: no-repeat;
  object-fit:fill; 
}
.mobpad{
  margin-top: 20px;
  margin-bottom: 20px;
}
.cover-image{
  width:100%;
  height:70vh;
  object-fit: contain;
  border-radius: 10px;
}
.probaground{
  width:100%;
  height:300px;
  border-radius:10px;
  background-repeat: no-repeat;
    object-fit:fill; 
}
}
::-webkit-scrollbar {
  width:0px;  /* Remove scrollbar space */
  background:transparent;  /* Optional: just make scrollbar invisible */
}
.ant-input{
  font-size: 13; 
  font-weight:'bold';
  color: #333333;
}
.printTbl{
  padding: 10px;
  border: 1px solid gray;
}
.pth{
  padding: 10px;
  border: 1px solid gray;
}
.ptd{
  padding: 10px;
  border: 1px solid gray; 
}
.scrollBox1{
  height:95vh;
  overflow:scroll;
}
.ton {
  position: relative;
  border: none;
  color: #000000;
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
}

.ton:after {
  content: "";
  background: #4f7cc4;
  border-radius: 10px;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px!important;
  margin-top: -120%;
  opacity: 0;
  -webkit-transition: all 0.8s;
  transition: all 0.8s
}

.ton:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  -webkit-transition: 0s;
  transition: 0s
}
.ton:hover {opacity: 0.6}
.usercolomn{
  color:#f20f29;
  font-weight: 500;
}
.targetcolomn{
  color:#31b56c;
  font-weight: 500;
}
.defultcolumn{
  font-weight: 500;
  color:#140cf2;
}
.mainsearchbox{
  width: 100%;
  padding: 9px 20px;
  box-sizing: border-box;
  border-radius:10px;
  background-color: #ebedf0;
  border: 0px solid #ebedf0;
  font-weight: 500;
}
.mainsearchbox:focus {
  background-color:#ebedf0;
}

.avatar {
  vertical-align: middle;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.flex-container {
  display: flex;
  justify-content: center;
  margin-top:10px;
}
.downimage{
    
  object-fit: contain;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btns {
  border: 2px solid #fff;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.ListHead
{
  text-transform: uppercase;
}

.textinline{
  font-size:17px;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  color: black;
  display: inline;
}
.textinline2{
  font-size:12px;
  font-family: 'Nunito', sans-serif;
  color: black;
}

.headermain{
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.18);
  background-color:'#fffff';

}
.popbtn{
    background-color: white;
    color:grey;
    border: 2px solid #fff; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width: 98%;
    text-align: left;
    padding: 8px;
    
    font-size: 15px;
    border-radius:0px;
    margin-left:2px;
    margin-right: 2px;
    font-family: 'Ubuntu-Regular';
    
}
.popbtn:hover {
    background-color: #e9ecf0; /* Green */
    color: #026c70;
  }
  .headerItem{
    background-color: transparent;
    color:#026c70;
    border: 2px solid transparent; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width:100%;
    text-align: left;
    padding-left:13px;
    padding-right:13px;
    font-weight: 500;
    font-size: 14x;
    margin-left:0px;
    margin-right:0px;
    position: relative;
  
    text-decoration: none;
   
}
.headerItem:lang(ml){
 
  font-size: 14px !important;

 
}



.headerItem:hover {
  color: #60cace;
}

.headerItem:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #60cace;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.headerItem:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}


  .popbtn1{
    margin-top:10px;
    background-color: #e9ecf0;
    color:#0070ba;
    border: 2px solid #e9ecf0; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width: 98%;
    text-align:center;
    padding: 8px;
    font-weight: bold;  
    font-size: 14px;
    border-radius:6px;
    margin-left:2px;
    margin-right: 2px;
    font-family: "Nunito";
}
.popuptxt{
  font-size:16px;
  font-family: 'Ubuntu-Medium', sans-serif;
  text-transform: uppercase;
  color: black;
  display: inline;
  text-overflow: ellipsis; 
  word-wrap: break-word;
}
.popuptxt2{
  font-size:13px;
  font-family: 'Ubuntu-Regular', sans-serif;
  color: grey;
  word-wrap: break-word;

}
.registerbtnnav{
  border: #026c70 1px solid;
  background-color:#026c70;
  align-items: center;
  text-align:center;
  border-radius: 6px;
}
.Drawerimage{
  width:100%;
  height: auto;
  object-fit: contain;
  border-radius:7px;
}
.registertxt{
  color: rgb(255, 255, 255);
  font-weight: 500;
  font-size: 13px;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-left: 25px;
  margin-right: 25px;
 
}
.logintxt{
  color: rgb(2, 108, 112);
  
    font-size: 13px;
    margin-Top: 6px;
    margin-bottom: 6px;
    margin-left: 20px;
    margin-right: 20px;
   
    font-weight: 500;
}
.MyprofileSpan{
  font-size:15px;
  font-family: 'Ubuntu-Medium';
  color: #026c70;
}
.MyprofileSpan:hover{
 
 
  color:  #36aeb3;
}
.HomeAvatarIcon{
margin-right: 8px;

}
.DrawerMenu{
  text-decoration: none;
  border-bottom: 1px solid #f1f1f1;
}
.DrawerMenu:hover{
  background-color: #e6f7ff;
  color: '#19767a';
}
.UserMenuItems{

  font-family: 'Ubuntu-Regular';
  font-size: 17px;
  color: #012a2c;
}

.UserMenuItems:lang(ml){

  font-size: 14px !important;
  
}
.UserMenuItems:hover{

  color: #026c70;
  
}
.HomeHeadMain:lang(ml){
    font-size: 19px !important;
    margin-bottom: 10px;
}

.HomeHeadSub:lang(ml){
    font-size: 14px !important;
}
.HomeSearchidButton:lang(ml){
    font-size: 14px !important;
}

.CardImage
{
    width: "250px";
    height: auto;
    align-items: center;
}
.example {
    text-align: center;
   
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
  }

  @media only screen and (max-width: 1366px) {
    .AboutContainer {
      margin-top: 40px;
      margin-bottom: 30px;
        
    }
    .Features{
        margin-top: 50px;
        margin-bottom: 50px;
    }
    } 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .AboutContainer {
        margin-top: 40px;
        margin-bottom: 30px;
          
      }
      .Features{
        margin-top: 50px;
        margin-bottom: 50px;
    }
  } 
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .AboutContainer {
        margin-top: 20px;
        margin-bottom: 30px;
          
      }
      .Features{
        margin-top: 50px;
        margin-bottom: 50px;
    }
    } 
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .AboutContainer {
        margin-top: 20px;
        margin-bottom: 30px;
          
      }
      .Features{
        margin-top: 20px;
        margin-bottom: 20px;
    }
  }


p:lang(ml){
    font-size: 14px !important;
    
  }


p:lang(ml){
    font-size: 14px !important;
    
  }
.snip1214 {
  font-family: 'Raleway', Arial, sans-serif;
  color: #000000;
  text-align: center;
  font-size: 16px;
  width: 100%;
  
  margin: 40px 10px;
}
.snip1214 .plan {
  margin: 3px;
  width: 24%;
  position: relative;
  float: left;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}
.snip1214 * {
  box-sizing: border-box;
}
.snip1214 header {
  position: relative;
}
.snip1214 .plan-title {
  position: relative;
  top: 0;
  font-weight: 800;
  padding: 5px 15px;
  margin: 0 auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  display: inline-block;
  background-color: #222f3d;
  color: #ffffff;
  text-transform: uppercase;
}
.snip1214 .plan-cost {
  padding: 0px 10px 20px;
}
.snip1214 .plan-price {
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 800;
  font-size: 2.4em;
  color: #34495e;
}
.snip1214 .plan-type {
  opacity: 0.6;
}
.snip1214 .plan-features {
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: outside none none;
  font-size: 0.9em;
}
.snip1214 .plan-features li {
  border-top: 1px solid #d2d7e2;
  padding: 10px 5%;
}
.snip1214 .plan-features li:nth-child(even) {
  background: rgba(0, 0, 0, 0.08);
}
.snip1214 .plan-features i {
  margin-right: 8px;
  opacity: 0.4;
}
.snip1214 .plan-select {
  border-top: 1px solid #d2d7e2;
  padding: 10px 10px 0;
}
.snip1214 .plan-select a {
  background-color: #222f3d;
  color: #ffffff;
  text-decoration: none;
  padding: 0.5em 1em;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
}
.snip1214 .plan-select a:hover {
  background-color: #46627f;
}
.snip1214 .featured {
  margin-top: -10px;
  background-color: #044f52;
  color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.snip1214 .featured .plan-title,
.snip1214 .featured .plan-price {
  color: #ffffff;
}
.snip1214 .featured .plan-cost {
  padding: 10px 10px 20px;
}
.snip1214 .featured .plan-features li {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
.snip1214 .featured .plan-select {
  padding: 20px 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
@media only screen and (max-width: 767px) {
  .snip1214 .plan {
    width: 100%;
  }
  .snip1214 .plan-title,
  .snip1214 .plan-select a {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .snip1214 .plan-cost,
  .snip1214 .featured .plan-cost {
    padding: 20px 10px 20px;
  }
  .snip1214 .plan-select,
  .snip1214 .featured .plan-select {
    padding: 10px 10px 10px;
  }
  .snip1214 .featured {
    margin-top: 0;
  }
}
@media only screen and (max-width: 440px) {
  .snip1214 .plan {
    width: 100%;
  }
}


p:lang(ml){
    font-size: 14px !important;
    
  }
  .example {
    text-align: center;
   
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
  }

H2:lang(ml){
    font-size: 18px !important;
    
  }
.image{
    width: 100%;
    height:auto;
    margin: 10px;
}
@media only screen and (max-width: 1366px) {
    .image {
        width: 100%;
        height:auto;
        
    }
    } 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .image {
        width: 100%;
        height:auto;
     
    }
  } 
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .image {
        width: 100%;
        height:auto;
      
    }
    } 
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .image {
        width: 100%;
        height:auto;
       padding-left: 10px;
       padding-top: 10px;
       padding-right: 10px;
       margin: 0px !important;
    }
  }
.listitem{
    margin-top: 15px;
}
.listitem:hover{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.listitemsub2{
    margin-top: 8px;
    margin-bottom:10px;
}
.listitemsub3{
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom:10px;
}
.listname{
    margin-right: 20px;
    padding-left: 10px;
    font-family: "Ubuntu-Medium";
    word-wrap: break-word;
    font-size: 20px;
    text-transform: uppercase;
    color:#484848;
}
.listname:hover{
    
    color: #000000;
}
.listid{
    margin-right: 20px;
    padding-left: 10px;
    font-size:15px;
    font-family: 'Ubuntu-Regular', sans-serif;
    color: grey;
    word-wrap: break-word;
  
}
.listitem3{
    margin-right: 5px;
    padding-left: 10px;
    margin-top:5px;
    font-family: 'Ubuntu-Regular', sans-serif; 
    color: #656565;
   
}
.ListNameLink:hover{
text-decoration: none;

}
.morebuttonbox{
    text-align: right;
}
.morebtn{
    margin-right: 20px;
}
.listboxheight{
    width: 100%;
    min-height:200px;
}
.listfooter{
    left: 0px;
    right: 0px;
    bottom: 0px;
    vertical-align: text-bottom;
}
.listbtn{
    background-color: white;
    color:grey;
    border: 2px solid #fff; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width: 98%;
    text-align: left;
    padding: 0px;
  
    font-size: 14px;
    border-radius:6px;
    margin-left:2px;
    margin-right: 2px;
    font-family: "Ubuntu-Regular";
    
}
.listbtn:hover{
   
    color: #020202;
 
    
}
.listiteminbtn{
    margin-top: 5px;
    background-color: #ffffff;
    color:#575858;
    border: 2px solid #c4c4c4; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width:100%;
    text-align: center;
    padding: 8px;
    letter-spacing: 0.5px;
    font-size:15px;
    border-radius:8px;
    font-weight: 500;
   
}
.listiteminbtnIntrest{
  margin-top: 5px;
  background-color: #ffffff;
  color:#575858;
  border: 2px solid #c4c4c4; /* Green */
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  width:100%;
  text-align: center;
  padding: 8px;
  letter-spacing: 0.5px;
  font-size:15px;
  border-radius:8px;
  font-weight: 500;
 
}
.listiteminbtnIntrest:hover{
  margin-top: 5px;
  background-color: #ffffff;
  color:#0070ba;
  border: 2px solid #c4c4c4; /* Green */
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  width:100%;
  text-align: center;
  padding: 8px;
  letter-spacing: 0.5px;
  font-size:15px;
  border-radius:8px;
  font-weight: 500;
 
}
.listiteminbtnShort
{
    margin-top: 5px;
    background-color: #ffffff;
    color:#575858;
    border: 2px solid #c4c4c4; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width:100%;
    text-align: center;
    padding: 8px;
    letter-spacing: 0.5px;
    font-size:15px;
    border-radius:8px;
    font-weight: 500;
   
}
.listiteminbtnShort:hover
{
    margin-top: 5px;
    background-color: #ffffff;
    color:#05aab0;
    border: 2px solid #c4c4c4; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width:100%;
    text-align: center;
    padding: 8px;
    letter-spacing: 0.5px;
    font-size:15px;
    border-radius:8px;
    font-weight: 500;
   
}
.listiteminbtnactiveShort{
  margin-top: 5px;
  background-color: #ffffff;
  color:#05aab0;
  border: 2px solid #c4c4c4; /* Green */
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  width:100%;
  text-align: center;
  padding: 8px;
  letter-spacing: 0.5px;
  font-size:15px;
  border-radius:8px;
  font-weight: 500;

}
.listiteminbtnactive{
  margin-top: 5px;
  background-color: rgb(218, 95, 95);
  color:#fff;
  border: 2px solid #fff; /* Green */
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  width:100%;
  text-align: center;
  padding: 8px;
  letter-spacing: 0.5px;
  font-size:15px;
  border-radius:8px;
  font-weight: 500;

}
.listiteminrejectbtn{
  margin-top: 5px;
  background-color: #df1e10da;
  color:#fff;
  border: 2px solid #fff; /* Green */
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  width:100%;
  text-align: center;
  padding: 8px;
  font-weight: 500;
  font-size:15px;
  border-radius:6px;
  letter-spacing: 0.5px;
 
}
.listiteminrequestbtn{
  margin-top: 5px;
  background-color: #0070ba;
  color:#fff;
  border: 2px solid #fff; /* Green */
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  width:100%;
  text-align: center;
  padding: 8px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size:15px;
  border-radius:8px;

 
}
.listitemacceptbtn{
  margin-top: 5px;
  background-color: #00ba7c;
  color:#fff;
  border: 2px solid #00ba7c; /* Green */
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  width:100%;
  text-align: center;
  padding: 8px;
  font-weight: 500;
  font-size:15px;
  border-radius:6px;
  font-weight: 500;
  letter-spacing: 0.5px;
 
}

 
  .listpopuprjbtn{
    width:100%;
    background-color: #fff;
    text-align: center;
    border: 2px solid #df1e10da;
    color:#df1e10da;
    border-radius:6px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 8px;
  }
  .listpopuprjbtn:hover{
    width:100%;
    background-color: #df1e10da;
    text-align: center;
    border: 2px solid #df1e10da;
    color:#fff;
    border-radius:6px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 8px;
  }
  .listpopupAcptbtn{
    width:100%;
    background-color: #fff;
    text-align: center;
    border: 2px solid #00ba7c;
    color:#00ba7c;
    border-radius:6px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 8px; 
  }
  .listpopupAcptbtn:hover{
    width:100%;
    background-color: #00ba7c;
    text-align: center;
    border: 2px solid #00ba7c;
    color:#fff;
    border-radius:6px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 8px; 
  }
  .listpopupcamcelbtn{
    width:100%;
    background-color: #fff;
    text-align: center;
    border: 2px solid #0070ba;
    color:#0070ba;
    border-radius:8px;
    font-weight: 500;
    padding: 8px; 
    letter-spacing: 0.5px;
  }
  .listpopupcamcelbtn:hover{
    width:100%;
    background-color: #0070ba;
    text-align: center;
    border: 2px solid #0070ba;
    color:#fff;
    border-radius:6px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 8px; 
  }
  .deactivationHead
  {

    font-family: 'Ubuntu-Medium';

  }

.deactivationSub
  {

    font-family: 'Ubuntu-Regular';
    font-size: 15px;
  }

.DeactivationCard
{
  background-color: #fff;
  border-color: #fe002a;
  margin-top: 20px;
}
.listiteminbtnShort:lang(ml){
  font-size: 13px !important;
}
.listiteminbtnactiveShort:lang(ml){
  font-size: 13px !important;
}
.listpopupcamcelbtn:lang(ml){
  font-size: 13px !important;
}
.listiteminbtnIntrest:lang(ml){
  font-size: 13px !important;
}
.listiteminrequestbtn:lang(ml){
  font-size: 13px !important;
}
.listitemacceptbtn:lang(ml){
  font-size: 13px !important;
}
.listiteminrejectbtn:lang(ml){
  font-size: 13px !important;
}
.deactivationSub:lang(ml){
  font-size: 14px !important;
}
.image{
    width: 100%;
    height:auto;
    margin: 10px;
}
@media only screen and (max-width: 1366px) {
    .image {
        width: 100%;
        height:auto;
        
    }
    } 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .image {
        width: 100%;
        height:auto;
     
    }
  } 
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .image {
        width: 100%;
        height:auto;
      
    }
    } 
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .image {
        width: 100%;
        height:auto;
       padding-left: 10px;
       padding-top: 10px;
       padding-right: 10px;
       margin: 0px !important;
    }
  }
.listitem{
    margin-top: 15px;
}
.listitem:hover{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.listitemsub2{
    margin-top: 8px;
    margin-bottom:10px;
}
.listitemsub3{
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom:10px;
}
.listname{
    margin-right: 20px;
    padding-left: 10px;
    font-family: "Ubuntu-Medium";
    word-wrap: break-word;
    font-size: 20px;
    text-transform: uppercase;
    color:#484848;
}
.listname:hover{
    
    color: #000000;
}
.listid{
    margin-right: 20px;
    padding-left: 10px;
    font-size:15px;
    font-family: 'Ubuntu-Regular', sans-serif;
    color: grey;
    word-wrap: break-word;
  
}
.listitem3{
    margin-right: 5px;
    padding-left: 10px;
    margin-top:5px;
    font-family: 'Ubuntu-Regular', sans-serif; 
    color: #656565;
   
}
.ListNameLink:hover{
text-decoration: none;

}
.morebuttonbox{
    text-align: right;
}
.morebtn{
    margin-right: 20px;
}
.listboxheight{
    width: 100%;
    min-height:200px;
}
.listfooter{
    left: 0px;
    right: 0px;
    bottom: 0px;
}
.listbtn{
    background-color: white;
    color:grey;
    border: 2px solid #fff; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width: 98%;
    text-align: left;
    padding: 0px;
  
    font-size: 14px;
    border-radius:6px;
    margin-left:2px;
    margin-right: 2px;
    font-family: "Ubuntu-Regular";
    
}
.listbtn:hover{
   
    color: #020202;
 
    
}


p:lang(ml){
    font-size: 14px !important;
    
  }
.profileimage{
    width:100%;
    height: auto;
    object-fit: contain;
    border-radius:7px;
}
.userbox{
  padding-top: 30px;
}
.username{
    word-wrap: break-word;
    line-height: 17px;
    font-family: 'Ubuntu-Medium', sans-serif;
  text-transform: uppercase;
    color: black;
    font-size: 16px;
    text-align: left;
    direction: none;
}
.registerd{
    font-size:12px;
    font-family: 'Ubuntu-Medium', sans-serif;
    color: gray;
    word-wrap: break-word;
    line-height: 18px;
 
  }
  .viewprofilebtn{
    background-color: #f0f2f5;
    color:grey;
    border: 2px solid #fff; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width: 98%;
    text-align: center;
    padding: 8px;
    font-weight: 500;
    font-size: 14px;
    border-radius:6px;
    font-family: "Nunito-bold";
    margin-top: 10px;
    line-height: 15px;
    word-wrap: break-word;
    
}
.viewprofilebtn:hover {
    background-color: #e9ecf0; /* Green */
    color: #0070ba;
  }
  .RightSideBar {
    border:1px solid rgb(230, 228, 228);
    background: #ffffff !important;
  }

 


  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .RightSideBar {display: none;}

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .RightSideBar {display: none;}

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

  .RightSideBar {display: none;}

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1100px) {

  .RightSideBar {background: #ffffff;
    display: inline;}

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

  .RightSideBar {background: #ffffff;}
}



.rightlistbox{
  margin-top: 25px;
  height:94vh;
  overflow:scroll;
}
  .rightheading{
    word-wrap: break-word;
    line-height: 18px;
    font-family: 'Ubuntu-Medium', sans-serif;
    
    color: #026c70;
    font-size: 18px;
    text-align: left;
    direction: none;
}
.rightheading:lang(ml){
  font-size: 14px !important;
}

.UserHomeShortlistMore{
  font-family: 'Ubuntu-Regular', sans-serif;
  font-size: 13px;
  color:#303030;
}
.UserHomeShortlistMore:hover{
  text-decoration: none;
}
.rightviewall{
  word-wrap: break-word;
  line-height: 18px;
  
text-decoration: none;

  text-align: right;
}
.UserHomeShortlistMore:lang(ml){
  font-size: 10px !important;
}
.rightlistitem{
  border-radius:10px;
  margin-top: 20px;
}
.rightlistitem:hover {
  background-color: #e8ebed;
}
.rightlistimage{
  width:100%;
    height: auto;
    object-fit: contain;
    border-radius:7px;
margin-right: 0px;
}
.rightlistname{
  word-wrap: break-word;
  font-family: 'Ubuntu-Medium', sans-serif;
 margin-top: 2px;
  color: black;
  font-size:14px;
  text-align: left;
  direction: none;
}
.rightlistregisterid{
  font-size:10px;
  font-family: 'Ubuntu-Medium', sans-serif;
  color: gray;
  word-wrap: break-word;
 text-decoration: none;
}

.DrawerMenu{
  text-decoration: none;
  border-bottom: 1px solid #f1f1f1;
}
.DrawerMenu:hover{
  background-color: #e6f7ff;
  color: '#19767a';
}

.UserMenuItems{

  font-family: 'Ubuntu-Regular';
  font-size: 17px;
  color: #012a2c;
}

.UserMenuItems:lang(ml){

  font-size: 14px !important;
  
}
.UserMenuItems:hover{

  color: #026c70;
  
}
.Logout:lang(ml){

  font-size: 15px !important;
  
}
::-webkit-scrollbar-track {
        width: 0px;
      }
.UserHomeBox{
        height:95vh;
        overflow:scroll;
}
.searchinput{
        margin-top:30px;
        width: 100%;
        box-sizing: border-box;
        border: 2px solid #ccc;
        border-radius: 6px;
        font-size: 16px;
        background-color: white;
        background-position: 10px 10px; 
        background-repeat: no-repeat;
        padding: 7px 20px 7px 40px;
        font-family: "Nunito";
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAflBMVEUAAAC0tLSys7Wys7Sys7Sys7Szs7Sxsba/v7+xs7Wxs7Oys7SxtLSvtraqqqqysrSys7SysrawtLSxs7OytLSxsbG2trazs7OytLSzs7Oys7Sys7SytLSys7Ourrmys7Sxs7Szs7S2trazs7Sys7StuLizs7S0tLSys7QAAACD33gDAAAAKHRSTlMAPajl+eSnOwSXlPV9IwZ+9jg6gKYkByWBPPf+lekW0+P6FdLXGb4s7biGIwAAAAFiS0dEAIgFHUgAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAxcSJwgA06uLAAAAjElEQVQY01WOWQ6DMBBDBxIStkAKpKWsXanvf8I2iyrFPx4/eSQTESUp45mQFJQX8CpzDwpUtWpUq1G6nKA6uaPTcF8p6vDbY7DGoAIwyKxxNAGcwePGBcyaQBvACGHtCt25LKfZb1uge9OYccK6+aWLHzqv2D0hOdzuTMht/5OgxxOvd0R+nYNicny+qxQLbCwu5qMAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDMtMjNUMTg6Mzk6MDgrMDA6MDDDKvXZAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTAzLTIzVDE4OjM5OjA4KzAwOjAwsndNZQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=);
}
.refreshbtn {
        margin-top:20px;
        background-color: #e9ecf0;
        color:#0070ba;
        border: 2px solid #e9ecf0; /* Green */
        -webkit-transition-duration: 0.4s;
                transition-duration: 0.4s;
        text-align:center;
        padding: 8px;
        font-weight: bold;  
        font-size: 14px;
        border-radius:6px;
        font-family: "Nunito";
}
.morebtns{
        margin-top:20px;
        background-color: #0070ba;
        color:#fff;
        border: 2px solid #0070ba;/* Green */
        -webkit-transition-duration: 0.4s;
                transition-duration: 0.4s;
        text-align:center;
        padding: 8px;
        font-weight: bold;  
        font-size: 14px;
        border-radius:6px;
        font-family: "Nunito";    
}
@media only screen and (max-width: 1366px) {
        
        } 
     /* Large devices (laptops/desktops, 992px and up) */
     @media only screen and (min-width: 992px) {
      
      } 
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        .refreshbtn {display: none;}
        .moreitems{display: none;}
        } 
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {
        .refreshbtn {display: none;}
        .moreitems{display: none;}
       
      }


      .flex-container{
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              overflow: scroll;
              justify-content: flex-start;
              margin-left:20px;
              margin-right: 5px;
      }

      .flex-container > div {
        margin-left:20px;
        text-align: center;
        background-color: #f1f1f1;
        margin: 10px;
        border-radius: 50px;
        font-family: "Nunito-SemiBold";
        font-weight: 500;
        padding:6px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 15px;
       

        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19);
      }
  
 
  

  
 

.HomeStatiMain{
    font-size: 21px;
   font-family:'Ubuntu-Medium' ;
   color: #026c70;
}
.HomeStatiSub{
    font-family:'Ubuntu-Regular' ;

font-size: 15px;
}
.HomeStatiSub:lang(ml){
    font-size: 13px !important;
}
.HomeStatiMainmob{
    font-size: 15px;
   font-family:'Ubuntu-Medium' ;
   color: #026c70;
   text-align: center;
   justify-content: 'center';
    align-items: 'center';
}

@media screen and (min-width: 600px) {
    .mob {
      display: none;
      align-items: center;
    }
  }
@media screen and (max-width: 600px) {
    .web {
      display: none;
    }
  }
  
.NotificationName{
font-size: 16px;


}
.NotificationHeading{
    font-size: 14px;
  
    
    }
    .HeaderTitile:lang(ml){

        font-size: 17;
        }

        .UserNotViewHead{
            font-size: 16px;
            font-weight: 600;
            text-decoration: none;
            color: #1d1d1d;
            text-transform: capitalize;
            }
            .UserNotViewHead:hover{
                font-size: 16px;
            
                text-decoration: none;
                
                color:#026c70;
                }
.SearchTab{
font-size: 16px;
font-family: 'Ubuntu-Regular';
color: #1a8a8e !important;
}
.SearchTab:active{
    color: white !important;
    background-color: #1a8a8e !important;
    }
    .SearchTab:focus{
   color: white !important;
        background-color: #1a8a8e !important;
        }
       
.SearchItems{
    font-size: 16px;
    font-family: 'Ubuntu-Medium';
  
}
.SearchButton{
    background-color: #1a8a8e !important;
    color: white !important;
    width: auto !important;
}
.SearchButton:hover{
    background-color: #48afb3 !important;
    color: white !important;
}
.SearchItems:lang(ml){
    font-size: 14px !important;
}


.DeatMain{
    font-weight: 500;
    border-bottom: 1px solid #eee;
    padding-bottom: 9px;
font-size: 14px;
margin-bottom: 9px;
}
.DeatSub{
    border-bottom: 1px solid #eee;
    padding-bottom: 9px;
    font-size: 14px;
    margin-bottom: 9px;
}
.DeatMainLast{
    font-weight: 500;
    border-bottom: 1px solid #eee;
    padding-bottom: 9px;
font-size: 14px;
margin-bottom: 15px;
}
.DeatSubLast{
    border-bottom: 1px solid #eee;
    padding-bottom: 9px;
    font-size: 14px;
    margin-bottom: 15px;
}

  
.DeatMain:lang(ml){
    font-size: 14px !important;
    font-weight: bolder;
}
.DetailPageContactLock
{
    margin-top: 5px;
    background-color: #ffffff;
    color:#575858;
    border: 2px solid #c4c4c4; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width:100%;
    text-align: center;
    padding: 8px;
    letter-spacing: 0.5px;
    font-size:15px;
    border-radius:8px;
    font-weight: 500;
   
}
.DetailPageContactLock:hover
{
    margin-top: 5px;
    background-color: #ffffff;
    color:#05aab0;
    border: 2px solid #c4c4c4; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width:100%;
    text-align: center;
    padding: 8px;
    letter-spacing: 0.5px;
    font-size:15px;
    border-radius:8px;
    font-weight: 500;
   
}
.DetailPageContactUnlock{
    margin-top: 5px;
    background-color: #ffffff;
    color:#05aab0;
    border: 2px solid #c4c4c4; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width:100%;
    text-align: center;
    padding: 8px;
    letter-spacing: 0.5px;
    font-size:15px;
    border-radius:8px;
    font-weight: 500;
  
  }
  .DetailPageWhatsApp{
  
    margin-top: 5px;
    background-color: #ffffff;
    color:#575858;
    border: 2px solid #c4c4c4; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width:100%;
    text-align: center;
    padding: 8px;
    letter-spacing: 0.5px;
    font-size:15px;
    border-radius:8px;
    font-weight: 500;
   
}
.DetailPageWhatsApp:hover{
  
    margin-top: 5px;
    background-color: #ffffff;
    color:#05aab0;
    border: 2px solid #c4c4c4; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width:100%;
    text-align: center;
    padding: 8px;
    letter-spacing: 0.5px;
    font-size:15px;
    border-radius:8px;
    font-weight: 500;
   
}
.DetailPageSMS{
  
    margin-top: 5px;
    background-color: #ffffff;
    color:#575858;
    border: 2px solid #c4c4c4; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width:100%;
    text-align: center;
    padding: 8px;
    letter-spacing: 0.5px;
    font-size:15px;
    border-radius:8px;
    font-weight: 500;
   
}
.DetailPageSMS:hover{
  
    margin-top: 5px;
    background-color: #ffffff;
    color:#05aab0;
    border: 2px solid #c4c4c4; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width:100%;
    text-align: center;
    padding: 8px;
    letter-spacing: 0.5px;
    font-size:15px;
    border-radius:8px;
    font-weight: 500;
   
}
  H5:lang(ml){
    font-size: 15px !important;
    font-weight: bolder;
}
H6:lang(ml){
    font-size: 14px !important;
    line-height: 1.6;
}
.DetailPageContactUnlock:lang(ml){
    font-size: 13px !important;
  
}
.DetailPageContactLock:lang(ml){
    font-size: 13px !important;
  
}
.DetailPageSMS:lang(ml){
    font-size: 13px !important;
  
}
.DetailPageSMS:lang(ml){
    font-size: 13px !important;
  
}
.DetailPageWhatsApp:lang(ml){
    font-size: 13px !important;
  
}
a{
    color:#575858 !important;
}
a:hover{
    color:#026c70 !important;
    text-decoration: none !important;
}
.MsgDate{
    float: right;
}
.UpdateHeader{
font-size: 16px;
font-family: 'Ubuntu-Medium';

}
.UpdateHeader:lang(ml){

    font-size: 12;
    font-weight: bolder;
    }

.intrestedBox {
    height:100vh;
    overflow: scroll;
}
@media only screen and (max-width: 1366px) {
    .intrestedBox {
        height:100vh;
        overflow: scroll;
        margin-top: 20px;
    }
    } 
    @media only screen and (max-width: 1200px) {
        .intrestedBox {
            height:100vh;
            overflow: scroll;
            margin-top: 20px;
        }   
    }
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .intrestedBox {
        height:100vh;
        overflow: scroll;
        margin-top: 20px;
    }
  } 
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .intrestedBox {
        height:100vh;
        overflow: scroll;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
    } 
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .intrestedBox {
        height:100%;
        overflow: scroll;
        margin-top: 20px;
        margin-left:0px;
        margin-right:0px;
    }
  }


 .UserIntrestDrop:lang(ml){

    font-size: 13px !important;
    }
.UserProViewHead{
font-size: 16px;
font-weight: 600;
text-decoration: none;
color: #1d1d1d;

}
.UserProViewHead:hover{
    font-size: 16px;

    text-decoration: none;
    
    color:#026c70;
    }

    .HeaderTitile:lang(ml){

        font-size: 17;
        }
.image{
    width: 100%;
    height:auto;
    margin: 10px;
}
@media only screen and (max-width: 1366px) {
    .image {
        width: 100%;
        height:auto;
        
    }
    } 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .image {
        width: 100%;
        height:auto;
     
    }
  } 
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .image {
        width: 100%;
        height:auto;
      
    }
    } 
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .image {
        width: 100%;
        height:auto;
       padding-left: 10px;
       padding-top: 10px;
       padding-right: 10px;
       margin: 0px !important;
    }
  }
.listitem{
    margin-top: 15px;
}
.listitem:hover{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.listitemsub2{
    margin-top: 8px;
    margin-bottom:10px;
}
.listitemsub3{
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom:10px;
}
.listname{
    margin-right: 20px;
    padding-left: 10px;
    font-family: "Ubuntu-Medium";
    word-wrap: break-word;
    font-size: 20px;
    text-transform: uppercase;
    color:#484848;
}
.listname:hover{
    
    color: #000000;
}
.listid{
    margin-right: 20px;
    padding-left: 10px;
    font-size:15px;
    font-family: 'Ubuntu-Regular', sans-serif;
    color: grey;
    word-wrap: break-word;
  
}
.listitem3{
    margin-right: 5px;
    padding-left: 10px;
    margin-top:5px;
    font-family: 'Ubuntu-Regular', sans-serif; 
    color: #656565;
   
}
.ListNameLink:hover{
text-decoration: none;

}
.morebuttonbox{
    text-align: right;
}
.morebtn{
    margin-right: 20px;
}
.listboxheight{
    width: 100%;
    min-height:200px;
}
.listfooter{
    left: 0px;
    right: 0px;
    bottom: 0px;
}
.listbtn{
    background-color: white;
    color:grey;
    border: 2px solid #fff; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width: 98%;
    text-align: left;
    padding: 0px;
  
    font-size: 14px;
    border-radius:6px;
    margin-left:2px;
    margin-right: 2px;
    font-family: "Ubuntu-Regular";
    
}
.listbtn:hover{
   
    color: #020202;
 
    
}
.image{
    width: 100%;
    height:auto;
    margin: 10px;
}
@media only screen and (max-width: 1366px) {
    .image {
        width: 100%;
        height:auto;
        
    }
    } 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .image {
        width: 100%;
        height:auto;
     
    }
  } 
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .image {
        width: 100%;
        height:auto;
      
    }
    } 
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .image {
        width: 100%;
        height:auto;
       padding-left: 10px;
       padding-top: 10px;
       padding-right: 10px;
       margin: 0px !important;
    }
  }
.listitem{
    margin-top: 15px;
}
.listitem:hover{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.listitemsub2{
    margin-top: 8px;
    margin-bottom:10px;
}
.listitemsub3{
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom:10px;
}
.listname{
    margin-right: 20px;
    padding-left: 10px;
    font-family: "Ubuntu-Medium";
    word-wrap: break-word;
    font-size: 20px;
    text-transform: uppercase;
    color:#484848;
}
.listname:hover{
    
    color: #000000;
}
.listid{
    margin-right: 20px;
    padding-left: 10px;
    font-size:15px;
    font-family: 'Ubuntu-Regular', sans-serif;
    color: grey;
    word-wrap: break-word;
  
}
.listitem3{
    margin-right: 5px;
    padding-left: 10px;
    margin-top:5px;
    font-family: 'Ubuntu-Regular', sans-serif; 
    color: #656565;
   
}
.ListNameLink:hover{
text-decoration: none;

}
.morebuttonbox{
    text-align: right;
}
.morebtn{
    margin-right: 20px;
}
.listboxheight{
    width: 100%;
    min-height:200px;
}
.listfooter{
    left: 0px;
    right: 0px;
    bottom: 0px;
}
.listbtn{
    background-color: white;
    color:grey;
    border: 2px solid #fff; /* Green */
    -webkit-transition-duration: 0.4s;
            transition-duration: 0.4s;
    width: 98%;
    text-align: left;
    padding: 0px;
  
    font-size: 14px;
    border-radius:6px;
    margin-left:2px;
    margin-right: 2px;
    font-family: "Ubuntu-Regular";
    
}
.listbtn:hover{
   
    color: #020202;
 
    
}
.shortlist {
    height:100vh;
    overflow: scroll;
}
@media only screen and (max-width: 1366px) {
    .shortlist {
        height:100vh;
        overflow: scroll;
        margin-top: 20px;
        margin-left: 15px;
        margin-right: 15px;
    }
    } 
    @media only screen and (max-width: 1200px) {
        .shortlist {
            height:100vh;
            overflow: scroll;
            margin-top: 20px;
            margin-left:15px;
            margin-right:15px;
        }   
    }
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .shortlist {
        height:100vh;
        overflow: scroll;
        margin-top: 20px;
        margin-left:15px;
        margin-right:15px;
    }
  } 
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .shortlist {
        height:100vh;
        overflow: scroll;
        margin-top: 20px;
        margin-left: 15px;
        margin-right: 15px;
    } 
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .shortlist {
        height:100%;
        overflow: scroll;
        margin-top: 20px;
        margin-left:15px;
        margin-right:15px;
    }
  }

    .HeaderTitile:lang(ml){

        font-size: 17px;
        }

    .UserPaymentHead{
font-family: 'Ubuntu-Medium';
font-size: 15px;
margin-top: 5px;
    }

    .UserPaymentHead:lang(ml){
     
        font-size: 13px;
        
            }
    .UserPaymentSub{

        font-family: 'Ubuntu-Regular';
font-size: 15px;
    }
    .UserpaymentHeadline{

        font-family: 'Ubuntu-Medium';
    }
    .UserpaymentHeadline:lang(ml){

        font-size: 16px !important;
        }
.snip1214 {
  font-family: 'Raleway', Arial, sans-serif;
  color: #000000;
  text-align: center;
  font-size: 16px;
  width: 100%;
  
  margin: 40px 10px;
}
.snip1214 .plan {
  margin: 3px;
  width: 24%;
  position: relative;
  float: left;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}
.snip1214 * {
  box-sizing: border-box;
}
.snip1214 header {
  position: relative;
}
.snip1214 .plan-title {
  position: relative;
  top: 0;
  font-weight: 800;
  padding: 5px 15px;
  margin: 0 auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  display: inline-block;
  background-color: #222f3d;
  color: #ffffff;
  text-transform: uppercase;
}
.snip1214 .plan-cost {
  padding: 0px 10px 20px;
}
.snip1214 .plan-price {
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 800;
  font-size: 2.4em;
  color: #34495e;
}
.snip1214 .plan-type {
  opacity: 0.6;
}
.snip1214 .plan-features {
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: outside none none;
  font-size: 0.9em;
}
.snip1214 .plan-features li {
  border-top: 1px solid #d2d7e2;
  padding: 10px 5%;
}
.snip1214 .plan-features li:nth-child(even) {
  background: rgba(0, 0, 0, 0.08);
}
.snip1214 .plan-features i {
  margin-right: 8px;
  opacity: 0.4;
}
.snip1214 .plan-select {
  border-top: 1px solid #d2d7e2;
  padding: 10px 10px 0;
}
.snip1214 .plan-select a {
  background-color: #222f3d;
  color: #ffffff;
  text-decoration: none;
  padding: 0.5em 1em;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
}
.snip1214 .plan-select a:hover {
  background-color: #46627f;
}
.snip1214 .featured {
  margin-top: -10px;
  background-color: #044f52;
  color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.snip1214 .featured .plan-title,
.snip1214 .featured .plan-price {
  color: #ffffff;
}
.snip1214 .featured .plan-cost {
  padding: 10px 10px 20px;
}
.snip1214 .featured .plan-features li {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
.snip1214 .featured .plan-select {
  padding: 20px 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
@media only screen and (max-width: 767px) {
  .snip1214 .plan {
    width: 100%;
  }
  .snip1214 .plan-title,
  .snip1214 .plan-select a {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .snip1214 .plan-cost,
  .snip1214 .featured .plan-cost {
    padding: 20px 10px 20px;
  }
  .snip1214 .plan-select,
  .snip1214 .featured .plan-select {
    padding: 10px 10px 10px;
  }
  .snip1214 .featured {
    margin-top: 0;
  }
}
@media only screen and (max-width: 440px) {
  .snip1214 .plan {
    width: 100%;
  }
}
.cardStyle{
    width: 100%;
}
.intrestedBox {
    height:100vh;
    overflow: scroll;
}
@media only screen and (max-width: 1366px) {
    .intrestedBox {
        height:100vh;
        overflow: scroll;
        margin-top: 20px;
    }
    } 
    @media only screen and (max-width: 1200px) {
        .intrestedBox {
            height:100vh;
            overflow: scroll;
            margin-top: 20px;
        }   
    }
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .intrestedBox {
        height:100vh;
        overflow: scroll;
        margin-top: 20px;
    }
  } 
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .intrestedBox {
        height:100vh;
        overflow: scroll;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
    } 
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    .intrestedBox {
        height:100%;
        overflow: scroll;
        margin-top: 20px;
        margin-left:0px;
        margin-right:0px;
    }
  }


 .UserIntrestDrop:lang(ml){

    font-size: 13px !important;
    }
::-webkit-scrollbar-track {
        width: 0px;
      }
.UserHomeBox{
        height:95vh;
        overflow:scroll;
}
.searchinput{
        margin-top:30px;
        width: 100%;
        box-sizing: border-box;
        border: 2px solid #ccc;
        border-radius: 6px;
        font-size: 16px;
        background-color: white;
        background-position: 10px 10px; 
        background-repeat: no-repeat;
        padding: 7px 20px 7px 40px;
        font-family: "Nunito";
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAflBMVEUAAAC0tLSys7Wys7Sys7Sys7Szs7Sxsba/v7+xs7Wxs7Oys7SxtLSvtraqqqqysrSys7SysrawtLSxs7OytLSxsbG2trazs7OytLSzs7Oys7Sys7SytLSys7Ourrmys7Sxs7Szs7S2trazs7Sys7StuLizs7S0tLSys7QAAACD33gDAAAAKHRSTlMAPajl+eSnOwSXlPV9IwZ+9jg6gKYkByWBPPf+lekW0+P6FdLXGb4s7biGIwAAAAFiS0dEAIgFHUgAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAxcSJwgA06uLAAAAjElEQVQY01WOWQ6DMBBDBxIStkAKpKWsXanvf8I2iyrFPx4/eSQTESUp45mQFJQX8CpzDwpUtWpUq1G6nKA6uaPTcF8p6vDbY7DGoAIwyKxxNAGcwePGBcyaQBvACGHtCt25LKfZb1uge9OYccK6+aWLHzqv2D0hOdzuTMht/5OgxxOvd0R+nYNicny+qxQLbCwu5qMAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDMtMjNUMTg6Mzk6MDgrMDA6MDDDKvXZAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTAzLTIzVDE4OjM5OjA4KzAwOjAwsndNZQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=);
}
.refreshbtn {
        margin-top:20px;
        background-color: #e9ecf0;
        color:#0070ba;
        border: 2px solid #e9ecf0; /* Green */
        -webkit-transition-duration: 0.4s;
                transition-duration: 0.4s;
        text-align:center;
        padding: 8px;
        font-weight: bold;  
        font-size: 14px;
        border-radius:6px;
        font-family: "Nunito";
}
.morebtns{
        margin-top:20px;
        background-color: #0070ba;
        color:#fff;
        border: 2px solid #0070ba;/* Green */
        -webkit-transition-duration: 0.4s;
                transition-duration: 0.4s;
        text-align:center;
        padding: 8px;
        font-weight: bold;  
        font-size: 14px;
        border-radius:6px;
        font-family: "Nunito";    
}
@media only screen and (max-width: 1366px) {
        
        } 
     /* Large devices (laptops/desktops, 992px and up) */
     @media only screen and (min-width: 992px) {
      
      } 
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        .refreshbtn {display: none;}
        .moreitems{display: none;}
        } 
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {
        .refreshbtn {display: none;}
        .moreitems{display: none;}
       
      }


      .flex-container{
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              overflow: scroll;
              justify-content: flex-start;
              margin-left:20px;
              margin-right: 5px;
      }

      .flex-container > div {
        margin-left:20px;
        text-align: center;
        background-color: #f1f1f1;
        margin: 10px;
        border-radius: 50px;
        font-family: "Nunito-SemiBold";
        font-weight: 500;
        padding:6px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 15px;
       

        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19);
      }
  
 
  

  
 

.SearchTab{
font-size: 16px;
font-family: 'Ubuntu-Regular';
color: #1a8a8e !important;
}
.SearchTab:active{
    color: white !important;
    background-color: #1a8a8e !important;
    }
    .SearchTab:focus{
   color: white !important;
        background-color: #1a8a8e !important;
        }
       
.SearchItems{
    font-size: 16px;
    font-family: 'Ubuntu-Medium';
  
}
.SearchButton{
    background-color: #1a8a8e !important;
    color: white !important;
    width: auto !important;
}
.SearchButton:hover{
    background-color: #48afb3 !important;
    color: white !important;
}
.SearchItems:lang(ml){
    font-size: 14px !important;
}

