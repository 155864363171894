.SearchTab{
font-size: 16px;
font-family: 'Ubuntu-Regular';
color: #1a8a8e !important;
}
.SearchTab:active{
    color: white !important;
    background-color: #1a8a8e !important;
    }
    .SearchTab:focus{
   color: white !important;
        background-color: #1a8a8e !important;
        }
       
.SearchItems{
    font-size: 16px;
    font-family: 'Ubuntu-Medium';
  
}
.SearchButton{
    background-color: #1a8a8e !important;
    color: white !important;
    width: auto !important;
}
.SearchButton:hover{
    background-color: #48afb3 !important;
    color: white !important;
}
.SearchItems:lang(ml){
    font-size: 14px !important;
}
