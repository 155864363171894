@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
}
.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  overflow: hidden;
}

.button{
  background-color:transparent;
  border: white 2px solid;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px 1px;
  cursor: pointer;
  font-family: 'Ubuntu-Regular';
}
.colorcontainer{
  background-image: radial-gradient(circle farthest-side at center top, #2bacb1, #0b4f52 130%)
}
.iconcontainer{
margin-top: 50px;
}
.itemscentr{
  align-items: center;
  text-align:center;
}
.logincontainer{
  min-height:93vh;
}

.usercontainer{
  min-height:90vh;
}
.fontsizelimit{
  font-size: 10px;
}
.imageupload{
  background: #b4b4b4;
  border-radius: 10px;
}
.aligncenter{
  align-items: center;
  text-align:center;
  margin-top: 80px;
  width: 100%;
}
.aligncenter1{
  align-items: center;
  text-align:center;
  margin-top: 40px;
  width: 100%;
}
.aligitemncenter{
  align-items: center;
  text-align:center;
  width: 100%;
  height:100%;
}
.height{
  height:325px;
  align-items: center;
  text-align:center;
  padding: 25px;
}
.height1{
  height:200px;
  align-items: center;
  text-align:center;
  padding: 25px;
}
.boxabout{
  border:1.4px solid #cfcfcf;
  border-radius: 10px;
  margin: 10px;
}
.fixedbox{
  height:90vh;
}
.fixedbox1{
  height:90vh;
  overflow:scroll;
}
.fixedbox2{
  height:93vh;
  overflow:scroll;
}
.change{
  height:80vh;
}
@media only screen and (max-width: 600px) {
  .navbottom{margin-bottom: 10px;margin-top: 10px;}
  .recomend-image{
    width:100%;
    height: auto;
    object-fit: contain;
    border: 1px solid #ddd;
    padding: 3px;
  }
  .scrollBox{
    overflow:scroll;
  }
  
  .scrollBoxadmin{
    overflow: hidden;
  }
  .padii{
    margin-bottom: 10px;
  }
  .pads {
    margin-left:0px;
    margin-right:0px; 
  }
  .mobpad{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .cover-image{
    display: none;
  }
  .probaground{
    width:100%;
    height:300px;
    border-radius:10px;
    background-repeat: contain;
      object-fit:none; 
  }
  .example1 {
    height:45vh;
    width: 100%;
    background-repeat: no-repeat;
    object-fit:none; 
  }
  .hidesection{
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .navbottom{
    margin-bottom: 10px;
    margin-top: 10px;
    display: inline-block;
    line-height: 20px;
  }
  .recomend-image{
    width: 100%;
    object-fit: contain;
    border: 1px solid #ddd;
    padding: 3px;
  }
  .scrollBox{
    overflow: scroll;
  }
  .padii{
    margin-bottom: 10px;
  }
  .pads {
    margin-left:30px;
    margin-right:30px; 
  }
  .example1 {
    height:50vh;
    width: 100%;
    background-repeat: no-repeat;
    object-fit:none; 
  }
  .mobpad{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .cover-image{
    width:100%;
    height:80vh;
    object-fit: contain;
    border-radius: 10px;
  }
  .probaground{
    width:100%;
    height:300px;
    border-radius:10px;
    background-repeat: contain;
      object-fit:none; 
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .navbottom{margin-bottom:0px;
    margin-top: 0px;
  display: inline-block;
  line-height: 20px;
}
  .scrollBox{
    height:100vh;
    overflow: scroll;
  }
  .padii{
    margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
    margin-bottom: 10px;
  }
  .pads {
    margin-left:30px;
    margin-right:30px; 
  }
  .padss {
    margin-left:10px;
    margin-right:10px; 
  }
  .listpad{
    margin:40px;
    margin-top: 0px;
  }
  .example1 {
    height:100%;
    width: 100%;
    background-repeat: no-repeat;
    object-fit:fill; 
  }
  .mobpad{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .cover-image{
    width:100%;
    height:80vh;
    object-fit: contain;
    border-radius: 10px;
  }
  .paddesk{
    margin:10px;
  }
  .probaground{
    width:100%;
    height:300px;
    border-radius:10px;
    background-repeat: no-repeat;
      object-fit:fill; 
  }
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .navbottom{margin-bottom:0px;
    margin-top: 0px;
  display: inline-block;
  line-height: 20px;
}
  .scrollBox{
    height:100vh;
    overflow: scroll;
  }
  .padii{
    margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
    margin-bottom: 10px;
  }
  .pads {
    margin-left:30px;
    margin-right:30px; 
  }
  .padss {
    margin-left:10px;
    margin-right:10px;
  }
  .listpad{
    margin-top: 0px;
    margin:40px;
  }
  .showhide{
    display: none;
  }
  .example1 {
    height:100%;
    width: 100%;
    background-repeat: no-repeat;
    object-fit:fill; 
  }
  .mobpad{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .cover-image{
    width:100%;
    height:80vh;
    object-fit: contain;
    border-radius: 10px;
  }
  .probaground{
    width:100%;
    height:300px;
    border-radius:10px;
    background-repeat: no-repeat;
      object-fit:fill; 
  }
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .navbottom{margin-bottom:0px;
    margin-top: 0px;
  display: inline-block;
  line-height: 20px;
}
  .recomend-image{
    width: 100%;
    height: auto;
    border: 1px solid #ddd;

  padding: 3px;
 
}
.ListItemSub{
font-size:14px ;
font-family: "Nunito-SemiBold";
color: #000000;
font-weight: 600;
 
}
.scrollBox{
  height:85vh;
  overflow: scroll;
}
.padii{
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.pads {
  margin-left:30px;
  margin-right:30px; 
}
.padss {
  margin-left:10px;
  margin-right:10px;
}
.listpad{
  margin:40px;
  margin-top: 0px;
}
.ppad{
  margin-left:50px;
  margin-right:50px; 
}
.showhide{
  display: none;
}
.example1 {
  height:100%;
  width: 100%;
  background-repeat: no-repeat;
  object-fit:fill; 
}
.mobpad{
  margin-top: 20px;
  margin-bottom: 20px;
}
.cover-image{
  width:100%;
  height:70vh;
  object-fit: contain;
  border-radius: 10px;
}
.probaground{
  width:100%;
  height:300px;
  border-radius:10px;
  background-repeat: no-repeat;
    object-fit:fill; 
}
}
::-webkit-scrollbar {
  width:0px;  /* Remove scrollbar space */
  background:transparent;  /* Optional: just make scrollbar invisible */
}
.ant-input{
  font-size: 13; 
  font-weight:'bold';
  color: #333333;
}
.printTbl{
  padding: 10px;
  border: 1px solid gray;
}
.pth{
  padding: 10px;
  border: 1px solid gray;
}
.ptd{
  padding: 10px;
  border: 1px solid gray; 
}
.scrollBox1{
  height:95vh;
  overflow:scroll;
}
.ton {
  position: relative;
  border: none;
  color: #000000;
  text-align: center;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
}

.ton:after {
  content: "";
  background: #4f7cc4;
  border-radius: 10px;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px!important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s
}

.ton:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}
.ton:hover {opacity: 0.6}
.usercolomn{
  color:#f20f29;
  font-weight: 500;
}
.targetcolomn{
  color:#31b56c;
  font-weight: 500;
}
.defultcolumn{
  font-weight: 500;
  color:#140cf2;
}
.mainsearchbox{
  width: 100%;
  padding: 9px 20px;
  box-sizing: border-box;
  border-radius:10px;
  background-color: #ebedf0;
  border: 0px solid #ebedf0;
  font-weight: 500;
}
.mainsearchbox:focus {
  background-color:#ebedf0;
}

.avatar {
  vertical-align: middle;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.flex-container {
  display: flex;
  justify-content: center;
  margin-top:10px;
}
.downimage{
    
  object-fit: contain;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btns {
  border: 2px solid #fff;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.ListHead
{
  text-transform: uppercase;
}

.textinline{
  font-size:17px;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  color: black;
  display: inline;
}
.textinline2{
  font-size:12px;
  font-family: 'Nunito', sans-serif;
  color: black;
}
